<template>
    <div class="caja-header d-flex flex-row justify-content-end align-items-center">
        <div class="contenedor-iconos flex-row">
            <router-link to="/dashboard/auditoria" v-if="auditoriaSelect === true" @click="selectIcons">
                <div class="caja-icono d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo d-flex justify-content-center align-items-center">
                        <div class="icono-auditoria"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/auditoria" v-if="auditoriaSelect === false" @click="selectIcons">
                <div class="caja-icono2 d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo2 d-flex justify-content-center align-items-center">
                        <div class="icono-auditoria-select"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/contabilidad" v-if="contabilidadSelect === true" @click="selectIcons">
                <div class="caja-icono d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo d-flex justify-content-center align-items-center">
                        <div class="icono-contabilidad"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/contabilidad" v-if="contabilidadSelect === false" @click="selectIcons">
                <div class="caja-icono2 d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo2 d-flex justify-content-center align-items-center">
                        <div class="icono-contabilidad-select"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/cuenta/inicio" v-if="cochinitoSelect === true" @click="selectIcons">
                <div class="caja-icono d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo d-flex justify-content-center align-items-center">
                        <div class="icono-cochinito"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/cuenta/inicio" v-if="cochinitoSelect === false" @click="selectIcons">
                <div class="caja-icono2 d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo2 d-flex justify-content-center align-items-center">
                        <div class="icono-cochinito-select"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/estadistica" v-if="graficasSelect === true" @click="selectIcons">
                <div class="caja-icono d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo d-flex justify-content-center align-items-center">
                        <div class="icono-grafica"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/estadistica" v-if="graficasSelect === false" @click="selectIcons">
                <div class="caja-icono2 d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo2 d-flex justify-content-center align-items-center">
                        <div class="icono-grafica-select"></div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="nombre-y-foto d-flex flex-row justify-content-center align-items-center" @click="activarDesplegable">
            <p class="texto-nombre">{{nombre}} {{apellido}}</p>
            <div class="cuadro-perfil-iniciales d-flex flex-row justify-content-center align-items-center" v-if="bulPerfil === false">{{urlImagen}}</div>
            <div class="cuadro-contenedor-espacio d-flex align-items-center justify-content-center" v-if="bulPerfil === true">
                <div class="circulo-perfil2 d-flex align-items-center justify-content-center">
                    <div class="cuadro-perfil-imagen" v-if="bulPerfil === true" :style="`height:${alturaImagen}%; width:${anchoImagen}%; margin-top:${topImagen}%; margin-left:${izquierdaImagen}%; background-image:url(${urlImagen});`"></div>
                </div>
            </div>
        </div>
        <div class="caja-desplegable d-flex justify-content-start align-items-center flex-column" v-if="bulDesplegable === true">
            <router-link to="/app/profile" class="arreglo-texto"><p class="texto-header-desplegable">Inicio</p></router-link>
            <router-link to="/app/billetera" class="arreglo-texto"><p class="texto-header-desplegable">Billetera</p></router-link>
            <router-link to="/app/tickets" class="arreglo-texto"><p class="texto-header-desplegable">Tickets</p></router-link>
            <router-link to="/app/configuracion" class="arreglo-texto"><p class="texto-header-desplegable">Configuración</p></router-link>
            <p class="texto-header-desplegable" @click="cerrarSesion">Cerrar sesión</p>
        </div>
    </div>
    <div class="contenedor-iconos-movil flex-row justify-content-around" :style="`margin-top:calc(${alturaVentana}px - 52.5px);`">
            <router-link to="/dashboard/auditoria" v-if="auditoriaSelect === true" @click="selectIcons">
                <div class="caja-icono-movil d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo-movil d-flex justify-content-center align-items-center">
                        <div class="icono-auditoria"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/auditoria" v-if="auditoriaSelect === false" @click="selectIcons">
                <div class="caja-icono2-movil d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo2-movil d-flex justify-content-center align-items-center">
                        <div class="icono-auditoria-select"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/contabilidad" v-if="contabilidadSelect === true" @click="selectIcons">
                <div class="caja-icono-movil d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo-movil d-flex justify-content-center align-items-center">
                        <div class="icono-contabilidad"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/contabilidad" v-if="contabilidadSelect === false" @click="selectIcons">
                <div class="caja-icono2-movil d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo2-movil d-flex justify-content-center align-items-center">
                        <div class="icono-contabilidad-select"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/cuenta/inicio" v-if="cochinitoSelect === true" @click="selectIcons">
                <div class="caja-icono-movil d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo-movil d-flex justify-content-center align-items-center">
                        <div class="icono-cochinito"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/cuenta/inicio" v-if="cochinitoSelect === false" @click="selectIcons">
                <div class="caja-icono2-movil d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo2-movil d-flex justify-content-center align-items-center">
                        <div class="icono-cochinito-select"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/estadistica" v-if="graficasSelect === true" @click="selectIcons">
                <div class="caja-icono-movil d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo-movil d-flex justify-content-center align-items-center">
                        <div class="icono-grafica"></div>
                    </div>
                </div>
            </router-link>
            <router-link to="/dashboard/estadistica" v-if="graficasSelect === false" @click="selectIcons">
                <div class="caja-icono2-movil d-flex justify-content-center align-items-center">
                    <div class="caja-icono-hijo2-movil d-flex justify-content-center align-items-center">
                        <div class="icono-grafica-select"></div>
                    </div>
                </div>
            </router-link>
        </div>
</template>

<script>
    import {mapState} from "vuex"
    import axios from 'axios'

    export default {
        name: "App",
        data(){
            return{
                nombre:'',
                apellido:'',
                urlImagen:'',
                bulPerfil: false,
                bulDesplegable: false,
                alturaVentana: 0,

                //menu de iconos
                auditoriaSelect: false,
                contabilidadSelect: false,
                cochinitoSelect: false,
                graficasSelect:false,

                //imagen
                alturaImagen: 100,
                anchoImagen:100,
                izquierdaImagen:0,
                topImagen:0

            }
        },
        methods:{
            //carga de informacion de la persona en header
            async loadHeader(){ 
                //consulta de apirest
                let userHeader = await axios.get(`${this.server}/information/header`, {

                    params: {token: localStorage.getItem('token')}}, 

                    {headers: {'Content-Type': 'application/json'}
                });

                if(userHeader.data.message === "Error en validación"){
                    this.$router.push({path: '/app/profile'})
                }

                //sustitucion de informacion

                this.anchoImagen = userHeader.data.ancho
                this.alturaImagen = userHeader.data.alto
                this.izquierdaImagen = userHeader.data.izquierda
                this.topImagen = userHeader.data.arriba
                this.alturaVentana = window.innerHeight

                //condicional para ver si el usuario ya elegido una foto de perfil

                if(userHeader.data.url === null || userHeader.data.url === false || userHeader.data.url === 'false'){
                    this.bulPerfil = false
                    this.urlImagen = `${userHeader.data.nombre[0]}${userHeader.data.nombre[0]}`
                }else{
                    this.bulPerfil = true
                    this.urlImagen = userHeader.data.url
                }

                //declaracion de variables

                let nombreEspacio
                let apellidoEspacio

                //pasamos la informacion suministrada por la api
                //comprobamos los espacios y saltadas de linea del campo nombre y apellido para acomodarlo correctamente en el header

                for(let i =0;i<10;i++){
                    if(userHeader.data.nombre[i] === ' ' || userHeader.data.nombre[i] === `
`){
                        nombreEspacio = i
                    }
                    if(userHeader.data.apellido[i] === ' ' || userHeader.data.apellido[i] === `
`){
                        apellidoEspacio = i 
                    }
                }

                //buscamos el primer nombre dentro de las 10 letras y declaramos

                if(nombreEspacio > 10){
                    this.nombre = userHeader.data.nombre.substring(0,10)
                }else{
                    this.nombre = userHeader.data.nombre.substring(0,nombreEspacio)
                }

                if(apellidoEspacio > 10){
                    this.apellido = userHeader.data.apellido.substring(0,10)
                }else{
                    this.apellido = userHeader.data.apellido.substring(0,apellidoEspacio)
                }

                // vemos en que uri nos encontramos para que aparezca seleccionado en el menu 
                if(localStorage.getItem('uri') === '1'){
                    this.auditoriaSelect = false;
                    this.contabilidadSelect = false;
                    this.cochinitoSelect = false;
                    this.graficasSelect = true;
                }
                if(localStorage.getItem('uri') === '2'){
                    this.auditoriaSelect = false;
                    this.contabilidadSelect = false;
                    this.cochinitoSelect = true;
                    this.graficasSelect = false;
                }
                if(localStorage.getItem('uri') === '3'){
                    this.auditoriaSelect = false;
                    this.contabilidadSelect = true;
                    this.cochinitoSelect = false;
                    this.graficasSelect = false;
                }
                if(localStorage.getItem('uri') === '4'){
                    this.auditoriaSelect = true;
                    this.contabilidadSelect = false;
                    this.cochinitoSelect = false;
                    this.graficasSelect = false;    
                }
            },

            //caja desplegable

            activarDesplegable(){
                if(this.bulDesplegable === false){
                    this.bulDesplegable = true
                }else{
                    this.bulDesplegable = false
                }
            },

            //selects de los iconos en el heder
            selectIcons(){
                // vemos en que uri nos encontramos para que aparezca seleccionado en el menu 
                
                setTimeout(() => {
                    if(localStorage.getItem('uri') === '1'){
                        this.auditoriaSelect = false;
                        this.contabilidadSelect = false;
                        this.cochinitoSelect = false;
                        this.graficasSelect = true;
                    }
                    if(localStorage.getItem('uri') === '2'){
                        this.auditoriaSelect = false;
                        this.contabilidadSelect = false;
                        this.cochinitoSelect = true;
                        this.graficasSelect = false;
                    }
                    if(localStorage.getItem('uri') === '3'){
                        this.auditoriaSelect = false;
                        this.contabilidadSelect = true;
                        this.cochinitoSelect = false;
                        this.graficasSelect = false;
                    }
                    if(localStorage.getItem('uri') === '4'){
                        this.auditoriaSelect = true;
                        this.contabilidadSelect = false;
                        this.cochinitoSelect = false;
                        this.graficasSelect = false;    
                    }
                }, 20);
            },

            //cerrar sesion

            cerrarSesion(){
                localStorage.setItem('token','')
                this.$router.push({path: '/login'})
            }

        },
        created () {
            this.loadHeader();
        },
        computed:{
        ...mapState(["token", "server"]),
        }
    }
</script>

<style scoped>
    .circulo-perfil2{
        position: relative;
        width: 100%;
        height: 100%;
        background: #297F87;
        border-radius:50% ;
        overflow: hidden;
    }
    .icono-auditoria,.icono-auditoria-select,.icono-contabilidad-select,.icono-contabilidad,.icono-cochinito,.icono-cochinito-select,.icono-grafica,.icono-grafica-select{
        width: 30px;
        height: 30px;
        margin-left:2px ;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .icono-auditoria{
        background-image:url(../assets/pictures/buscarNegro.svg);
    }
    .icono-auditoria-select{
        background-image:url(../assets/pictures/buscarBlanco.svg);
    }
    .icono-contabilidad{
        background-image:url(../assets/pictures/estructuracionNegro.svg);
    }
    .icono-contabilidad-select{
        background-image:url(../assets/pictures/estructuracionBlanco.svg);
    }
    .icono-cochinito{
        background-image:url(../assets/pictures/cochinitoNegro.svg);
        height: 36px;
        width: 36px;
    }
    .icono-cochinito-select{
        background-image:url(../assets/pictures/cochinitoBlanco.svg);
        height: 36px;
        width: 36px;
    }
    .icono-grafica{
        background-image:url(../assets/pictures/estadisticaNegro.svg);
        height: 32.5px;
    }
    .icono-grafica-select{
        background-image:url(../assets/pictures/estadisticaBlanco.svg);
        height: 32.5px;
    }
    .contenedor-iconos{
        margin-left: 120px;
        width: 460px;
        perspective: 300px;
        display:flex;
    }
    .caja-icono{
        width: 70px;
        height: 65px;
        margin-left:32.5px ;
        background: white;
        border-radius:0px 40%;
        animation-duration: 1s;
        animation-name:desvanece ;
    }
    .caja-icono-hijo{
        width: 87.5%;
        height: 100%;
        background: linear-gradient(135deg, #00918E -0.14%, #F0F0F0 76.03%);
        border-radius:0px 40%;
        animation-duration: 1s;
        animation-name:desvanece2 ;
    }
    @keyframes desvanece {
        0%{
            background: #110133;
        }
        100%{
            background: white;
        }
    }
    @keyframes desvanece2 {
        0%{
            opacity: 0;
        }
        100%{
            opacity:1;
        }
    }
    .caja-icono2{
        width: 70px;
        height: 65px;
        margin-left:32.5px ;
        border-radius:0px 40%;
    }
    .caja-icono-hijo2{
        width: 87.5%;
        height: 100%;
        border-radius:0px 40%;
    }
    .contenedor-iconos-movil{
        top:0px;
        width: 100%;
        height: 0px;
        display:none;
        position:fixed;
        z-index:50;
    }
    .caja-icono-movil{
        width: 60px;
        height: 52.5px;
        background: white;
        border-radius:0px 40%;
        animation-duration: 1s;
        animation-name:desvanece ;
    }
    .caja-icono-hijo-movil{
        width: 87.5%;
        height: 100%;
        background: linear-gradient(135deg, #00918E -0.14%, #F0F0F0 76.03%);
        border-radius:0px 40%;
        animation-duration: 1s;
        animation-name:desvanece2 ;
    }
    .caja-icono2-movil{
        width: 60px;
        height: 52.5px;
        border-radius:0px 40%;
        background: #110133;
    }
    .caja-icono-hijo2-movil{
        width: 87.5%;
        height: 100%;
        border-radius:0px 40%;
        background: #110133;
    }
    .caja-desplegable{
        position: absolute;
        top: 90px;
        width: 150px;
        height: 245px;
        right: 170px;
        padding-top:20px;
        background: #110133;
        border-radius: 10px;
    }
    .arreglo-texto{
        text-decoration: none;
        width: 100%;
        cursor: pointer;
    }
    .texto-header-desplegable{
        font-family: Rubik;
        font-weight: bold;
        padding-top:10px ;
        padding-bottom: 10px;
        margin: 0px;
        font-size: 14px;
        color: white;
        width: 100%;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
    }
    .texto-header-desplegable:hover{
        background:#297f87be ;
    }
    .caja-header{
        background: #110133;
        position: fixed;
        height: 65px;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 10;
        box-shadow: 5px -4px 40px rgba(0, 0, 0, 0.5);
    }
    .nombre-y-foto{
        margin-right: 90px;
        width: 300px;
        cursor: pointer;
    }
    .texto-nombre{
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
        color: white;
        margin-top:17.5px ;
    }
    .cuadro-perfil-iniciales{
        width: 50px;
        height: 50px;
        border-radius:50%;
        background: #297f87be;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
        margin-left:20px ;
        color: white;
    }
    .cuadro-perfil-imagen{
        border-radius:50%;
        background-position: center;
        background-repeat:no-repeat ;
        background-size: 100% 100%;
        background-color: #297f87be;
        margin-left:20px ;
        user-select: none;
        position: absolute;
    }
    .cuadro-contenedor-espacio{
        width:50px;
        height: 50px;
        margin-left:20px ;
    }
    @media (max-width: 900px) { 
        .caja-icono,.caja-icono-hijo,.caja-icono2,.caja-icono-hijo2,.contenedor-iconos{
            display:none;
        }
        .caja-icono-movil,.caja-icono-hijo-movil,.caja-icono2-movil,.caja-icono-hijo2-movil,.contenedor-iconos-movil{
            display:flex;
        }
        .nombre-y-foto{
            margin-right:-40px;
        }
        .caja-desplegable{
            margin-right:-135px;
        }
    }
</style>