<template>
        <!-- Interfaz de errores y conseguido -->
        <teleport to="body">
            <div class="container-start">
                <div class="error-profile" v-if="bulError === true">
                    <div class="container-icon">
                        <IconError/>
                    </div>
                    <span>{{mensaje}}</span>
                </div>  
                <div class="correcto-profile" v-if="bulCorrecto === true">
                    <div class="container-icon">
                        <IconSucessfully/>
                    </div>
                    <span>{{mensaje}}</span>
                </div>
            </div>
        </teleport>
</template>

<script>
    import {mapState} from "vuex"
    import IconError from './IconError.vue'
    import IconSucessfully from './IconSucessfully.vue'

    export default {
        components: { IconSucessfully, IconError },
        name: "MessagesRequest",
        computed:{
            ...mapState([
                "bulError",
                "bulCorrecto",
                "bulInfo",
                "mensaje"
            ]),
        },

    }
</script>

<style scoped>
    .error-profile,.correcto-profile,.info-profile{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 275px;
        gap: 10px;
        height: auto;
        background-color: #FFFFFF;
        color: #000000;
        margin-left:auto ;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        padding: 17px 12px;
        z-index: 100;
        font-family: Rubik;
        font-weight: bold;
        text-align: left;
        font-size: 14px;
        margin-top: 20px;
        margin-right: 29px;
        border-radius:15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        position: absolute;    
        top: 0;
        right: 0;
    }
    .container-icon{
        width: 25px;
        height: 100%;
        margin-right:10px ;
    }
    
</style>