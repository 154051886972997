<template>
    <div 
        ref="BOX"
        :class="((bul)) ?
            'box-mensaje-true' :
            'box-mensaje-false'
        " 
        :style="`
            left: ${left}; 
            top: ${top};
            ${transition}
            ${display}
        `"  
        
    >
        {{mensaje}}
        <div class="arrow-box"></div>
    </div>
</template>

<script>
    import {mapActions} from "vuex"

    export default {
        name: "MessagesTextComplete",
        data() {
            return {
                left: '0px',
                top: '0px',
                bul: false,
                mensaje: '',
                clock1: '',
                clock2: '',
                transition: 'transition: all 0s ease-in-out;',
                display: 'display: none;',
            }
        },
        created() {
            document.addEventListener('mouseover', this.viewTarget)
        },
        methods: {
            ...mapActions(["setStateManagmentData"]),
            async viewTarget(e) {
                if(e.target.className === 'container-hover' || e.target.className === 'box-mensaje-true' || e.target.className === 'box-mensaje-false' ||  e.target.className === 'arrow-box'){
                    
                    let numCalcPadding = (Number(e.target.parentElement.clientWidth) - (Number(getComputedStyle(e.target.parentElement, null).paddingLeft.slice(0,-2)) * 2))

                    if(e.target.parentElement.children[0].clientWidth === numCalcPadding && e.target.className === 'container-hover'){
                        
                        this.mensaje = e.target.parentElement.children[0].textContent;
                        this.target = e.target.parentElement.children[0];

                        let widthMemory = e.target.parentElement.children[0].getBoundingClientRect().width
                        let topMemory = e.target.getBoundingClientRect().top
                        let leftMemory = e.target.getBoundingClientRect().left

                        this.display = 'display: flex;'
                        this.transition = 'transition: all 0.5s ease-in-out;'

                        this.left = `${leftMemory + (widthMemory/2)}px`
                        this.top = `${topMemory}px`

                        this.clock1 = setTimeout(() => {
                            this.bul = true;
                        }, 40);

                    }else{
                        if(e.target.className !== 'box-mensaje-true' && e.target.className !== 'box-mensaje-false' ){                          
                            this.bul = false;  
                            clearTimeout(this.clock1)
    
                        }
                    }
                }else if(e.target.className !== 'box-mensaje-true' || e.target.className !== 'box-mensaje-false' || e.target.className !== 'container-hover'){
                    
                    this.bul = false;
                    clearTimeout(this.clock1)
                }
            }
        },
        watch: {
            bul(e) {
                if(e === false){
                    this.clock2 = setTimeout(() => {
                        this.mensaje = ''
                        this.transition = 'transition: all 0s ease-in-out;';
                        this.display = 'display: none;';
                    }, 750);
                }else{
                    clearTimeout(this.clock2)
                }
            },
        }
    }
</script>

<style scoped>
    .box-mensaje-true,.box-mensaje-false{
        position: fixed;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-content: center;
        max-width: 200px;
        min-width: 100px;
        height: auto;
        padding: 20px;
        transform: scale(0.8) translateX(-50%) translateY(-110%);
        opacity: 1;
        background-color: rgba(0,0,0,.85);
        border-radius: 5px;
        word-break: break-all;
        color: white;
        box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
        cursor: default;
    }
    .box-mensaje-false{
        transform: scale(0.3) translateX(-50%) translateY(-110%);
        opacity: 0;
    }
    .arrow-box{
        position: absolute;
        top: calc(100% - 1px);
        left: calc(50% - 10px);
        width: 20px;
        height: 20px;
        background-color: rgba(0,0,0,.85);
        clip-path: polygon(50% 100%, 0 0, 100% 0);
    }
</style>