<template>
  <svg :width="width" :height="height" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.666 23.2285C15.9209 23.9736 14.7041 23.9736 13.959 23.2285L9.58398 18.8535C8.83887 18.1084 8.83887 16.8916 9.58398 16.1465C10.3291 15.4014 11.5459 15.4014 12.291 16.1465L15.3125 19.168L22.709 11.7715C23.4541 11.0264 24.6709 11.0264 25.416 11.7715C26.1611 12.5166 26.1611 13.7334 25.416 14.4785L16.666 23.2285ZM35 17.5C35 27.166 27.166 35 17.5 35C7.83398 35 0 27.166 0 17.5C0 7.83398 7.83398 0 17.5 0C27.166 0 35 7.83398 35 17.5ZM17.5 3.28125C9.64551 3.28125 3.28125 9.64551 3.28125 17.5C3.28125 25.3545 9.64551 31.7188 17.5 31.7188C25.3545 31.7188 31.7188 25.3545 31.7188 17.5C31.7188 9.64551 25.3545 3.28125 17.5 3.28125Z" :fill="fill"/>
</svg>
</template>


<script>
    export default {
        name: "IconSuccesfully",
        props:{
            height:{
                type: String,
                default: '30'
            },
            width: {
                type: String,
                default: '30'
            },
            fill: {
                type: String,
                default: '#297F87'
            }
        }
    }
</script>