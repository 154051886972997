<template>
    <Header />
    <router-view ref="view"/>
    <MessagesRequest />
    <MessagesTextComplete />
    <div class="fondo-degradado-recover"></div> 
    <div class="white-background"></div>
    <Footer />
</template>

<script>
    import Footer from "../../components/Footer.vue"
    import Header from "../../components/HeaderDashboard.vue"
    import MessagesRequest from "../../components/MessagesRequest.vue"
    import MessagesTextComplete from "../../components/MessagesTextComplete.vue"

    export default {
        name: "App",
        components: {
            Footer,
            Header,
            MessagesRequest,
            MessagesTextComplete
        },
    }    
</script>

<style scoped>
    .fondo-degradado-recover{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
        z-index: -10;
        height: 100vh;
    }
    .white-background{
        background:white; 
        position: fixed;
        top: 0px; 
        left: 0px; 
        width: 100%; 
        z-index:-20; 
        height:100vh;
    }
</style>