<template>
  <svg :width="width" :height="height" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 3.94144C10.0118 3.94144 3.94144 10.0118 3.94144 17.5C3.94144 24.9882 10.0118 31.0586 17.5 31.0586C24.9882 31.0586 31.0586 24.9882 31.0586 17.5C31.0586 10.0118 24.9882 3.94144 17.5 3.94144ZM0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5C35 27.165 27.165 35 17.5 35C7.83502 35 0 27.165 0 17.5Z" :fill="fill"/>
<path d="M19.9437 25.5404C19.9437 26.89 18.8496 27.9841 17.5 27.9841C16.1504 27.9841 15.0563 26.89 15.0563 25.5404C15.0563 24.1908 16.1504 23.0967 17.5 23.0967C18.8496 23.0967 19.9437 24.1908 19.9437 25.5404Z" :fill="fill"/>
<path d="M15.687 9.4593V18.6823C15.687 19.2341 15.687 20.4953 17.5 20.4953C18.9505 20.4953 19.3131 19.2866 19.3131 18.6823V9.4593C19.3656 8.85495 19.0766 7.64624 17.5 7.64624C15.9235 7.64624 15.6344 8.85495 15.687 9.4593Z" :fill="fill"/>
</svg>
</template>

<script>
    export default {
        name: "IconSuccesfully",
        props:{
            height:{
                type: String,
                default: '30'
            },
            width: {
                type: String,
                default: '30'
            },
            fill: {
                type: String,
                default: '#C45555'
            }
        }
    }
</script>